var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
class ProductGallery {
  constructor({
    thumbsSliderSelector = ".js-product-thumbs",
    mainSliderSelector = ".js-product-main-images",
    modalSliderSelector = ".js-modal-gallery",
    galleryModalSelector = ".js-product-images-modal"
  } = {}) {
    this.thumbsSliderSelector = thumbsSliderSelector;
    this.mainSliderSelector = mainSliderSelector;
    this.modalSliderSelector = modalSliderSelector;
    this.galleryModalSelector = galleryModalSelector;
    this.mainSliderSwiperInstance = null;
    this.modalSliderSwiperInstance = null;
  }
  init() {
    this.mainSliderSwiperInstance = null;
    this.modalSliderSwiperInstance = null;
    this.initProductImageSlider();
    this.initModalGallerySlider();
  }
  initProductImageSlider() {
    return __async(this, null, function* () {
      const thumbsElem = document.querySelector(this.thumbsSliderSelector);
      const galleryTopElem = document.querySelector(this.mainSliderSelector);
      if (!thumbsElem && !galleryTopElem) {
        return;
      }
      const galleryThumbs = new prestashop.SwiperSlider(thumbsElem, {
        direction: "vertical",
        slidesPerView: 4.3,
        slideToClickedSlide: true,
        // centeredSlides: false,
        // centerInsufficientSlides: true,
        freeMode: true,
        spaceBetween: 10,
        mousewheel: true,
        // breakpoints: {
        //   320: {
        //     slidesPerView: 3,
        //   },
        //   576: {
        //     slidesPerView: 4,
        //   },
        // },
        navigation: {
          nextEl: thumbsElem.querySelector(".slider-nav__next"),
          prevEl: thumbsElem.querySelector(".slider-nav__prev")
        },
        watchSlidesVisibility: true,
        watchSlidesProgress: true
      });
      const galleryThumbsInstance = yield galleryThumbs.initSlider();
      const mainSlider = new prestashop.SwiperSlider(galleryTopElem, {
        spaceBetween: 32,
        slidesPerView: 1,
        freeMode: false,
        grabCursor: true,
        navigation: {
          nextEl: galleryTopElem.querySelector(".slider-nav__next"),
          prevEl: galleryTopElem.querySelector(".slider-nav__prev")
        },
        thumbs: {
          swiper: galleryThumbsInstance
        }
      });
      const mainSliderInstance = yield mainSlider.initSlider();
      this.mainSliderSwiperInstance = mainSliderInstance;
    });
  }
  initModalGallerySlider() {
    const gallerySliderElem = document.querySelector(this.modalSliderSelector);
    if (!gallerySliderElem) {
      return;
    }
    const handleModalOpen = () => __async(this, null, function* () {
      if (this.modalSliderSwiperInstance) {
        gallerySliderElem.style.opacity = 0;
        setTimeout(() => {
          this.modalSliderSwiperInstance.update();
          this.modalSliderSwiperInstance.slideTo(this.mainSliderSwiperInstance ? this.mainSliderSwiperInstance.activeIndex : 0, 0);
          gallerySliderElem.style.opacity = 1;
        }, 200);
      } else {
        const modalSlider = new prestashop.SwiperSlider(gallerySliderElem, {
          slidesPerView: 1,
          spaceBetween: 10,
          initialSlide: this.mainSliderSwiperInstance ? this.mainSliderSwiperInstance.activeIndex : 0,
          navigation: {
            nextEl: gallerySliderElem.querySelector(".swiper-button-next"),
            prevEl: gallerySliderElem.querySelector(".swiper-button-prev")
          }
        });
        const modalSliderInstance = yield modalSlider.initSlider();
        this.modalSliderSwiperInstance = modalSliderInstance;
      }
    });
    $(this.galleryModalSelector).on("show.bs.modal", handleModalOpen);
  }
}
export default ProductGallery;
