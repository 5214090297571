import $ from "jquery";
const _body = document.body;
export function MenuClose($body, $element) {
  "use strict";
  $(".bg-mask, .close-menu").on("click touch", function() {
    if (_body.classList.contains($element) && _body.classList.contains($body)) {
      _body.classList.remove($element);
      _body.classList.remove($body);
      HideMask();
      setTimeout(function() {
        $(".c-right-menu__cart").show();
        $(".c-right-login").hide();
      }, 600);
    }
    return false;
  });
}
export function HideMask() {
  $(".bg-mask").fadeOut(250, function() {
    $(".bg-mask").remove();
  });
}
export function ShowMask() {
  const mask = '<div class="bg-mask">';
  const mask_element = ".bg-mask";
  const bodyElm = $("body");
  if (bodyElm.find(".bg-mask").length === 0) {
    $(mask).hide().appendTo("body");
    bodyElm.find(mask_element).height($(document).height()).fadeIn(250);
  }
  $(window).resize(function() {
    bodyElm.find(mask_element).height($(document).height());
  });
}
