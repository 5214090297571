import $ from "jquery";
import { ShowMask, MenuClose, HideMask } from "./mask";
const _body = document.body;
$(document).on("mouseenter", ".product-preview", function() {
});
$(document).on("mouseenter", ".product_image_color", function() {
  const id = $(this).data("product-image-id");
  $(this).parents(".b-product__inner").find(".img_1").attr(
    "src",
    $(this).parents(".b-product__inner").find("[data-product-preview-image-id=" + id + "]").data("value")
  );
});
$(document).on("mouseover", ".product_image_color", function() {
  $(this).parents(".b-product__inner").find(".img_1").attr("src", $(this).data("src"));
});
function LeftMenu() {
  $(".push-menu").on("click touch", function(e) {
    e.preventDefault();
    setTimeout(function() {
      var body_class = "c-left-menu--opened";
      var element_class = "c-left-menu--push";
      _body.classList.add(body_class);
      _body.classList.add(element_class);
      ShowMask();
      MenuClose(body_class, element_class);
    }, 100);
  });
}
function RightMenu() {
  $("body").on("click touch", ".init-right-menu", function(e) {
    e.preventDefault();
    var cart_nb = $(this).data("cartitems");
    let body_class = "c-right-menu--opened";
    let element_class = "c-right-menu--push";
    if (cart_nb > 0) {
      setTimeout(function() {
        _body.classList.add(body_class);
        _body.classList.add(element_class);
        ShowMask();
        MenuClose(body_class, element_class);
      }, 100);
    } else {
      window.location = $(this).data("cart-link");
    }
  });
}
function initMenu() {
  var body_class = "c-left-menu--opened";
  var element_class = "c-left-menu--push";
  $(".init-menu").on("click touch", function(e) {
    e.preventDefault();
    if (_body.classList.contains(element_class) && _body.classList.contains(body_class)) {
      _body.classList.remove(element_class);
      _body.classList.remove(body_class);
      HideMask();
      $(this).removeClass("close-menu");
    } else {
      setTimeout(function() {
        _body.classList.add(body_class);
        _body.classList.add(element_class);
        ShowMask();
        $(this).addClass("close-menu");
      }, 1);
    }
  });
  $(".b-mainmenu.position-absolute").on("mouseleave", function(event) {
    if (_body.classList.contains(element_class) && _body.classList.contains(body_class)) {
      _body.classList.remove(element_class);
      _body.classList.remove(body_class);
      HideMask();
      MenuClose(body_class, element_class);
    }
  });
}
function searchPanel() {
  let searchElement = $(".search-form__input");
  const closeBtn = $(".js-search-close");
  const body = $("body");
  $(".js-init-search").on("click", function(e) {
    e.preventDefault();
    console.log("search");
    searchElement.focus();
    if (body.hasClass("search-opened")) {
      body.removeClass("search-opened");
      searchElement.focus();
    } else {
      body.toggleClass("search-opened");
      searchElement.focus();
    }
  });
  closeBtn.on("click", function() {
    body.removeClass("search-opened");
    searchElement.val("");
  });
}
function initMobileMenu() {
  $("#menu-icon").click(function() {
    $(this).toggleClass("open-menu");
    var hClass = $(this).hasClass("open-menu");
    if (hClass) {
      $(window).resize(function() {
        if ($(window).width() < 992) {
          $(this).parents("body").css("overflow", "hidden");
        }
      });
      $(this).parents("body").find("#mobile_top_menu_wrapper").addClass("box-menu");
    } else {
      $(this).parents("body").css("overflow", "visible");
      $(this).parents("body").find("#mobile_top_menu_wrapper").removeClass("box-menu");
    }
  });
  $(".menu-close").click(function() {
    $(this).parents("body").css("overflow", "visible");
    $(this).parents("body").find("#mobile_top_menu_wrapper").removeClass("box-menu");
    $(this).parents("body").find("#menu-icon").removeClass("open-menu");
  });
}
$(() => {
  LeftMenu();
  RightMenu();
  initMenu();
  searchPanel();
  initMobileMenu();
});
