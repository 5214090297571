if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function(callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector || Element.prototype.oMatchesSelector || Element.prototype.webkitMatchesSelector || function(s) {
    var matches = (this.document || this.ownerDocument).querySelectorAll(s), i = matches.length;
    while (--i >= 0 && matches.item(i) !== this) {
    }
    return i > -1;
  };
}
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;
    do {
      if (el.matches(s))
        return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
(function(arr) {
  arr.forEach(function(item) {
    if (item.hasOwnProperty("after")) {
      return;
    }
    Object.defineProperty(item, "after", {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function after() {
        var argArr = Array.prototype.slice.call(arguments), docFrag = document.createDocumentFragment();
        argArr.forEach(function(argItem) {
          var isNode = argItem instanceof Node;
          docFrag.appendChild(
            isNode ? argItem : document.createTextNode(String(argItem))
          );
        });
        this.parentNode.insertBefore(docFrag, this.nextSibling);
      }
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
(function(arr) {
  arr.forEach(function(item) {
    if (item.hasOwnProperty("append")) {
      return;
    }
    Object.defineProperty(item, "append", {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function append() {
        var argArr = Array.prototype.slice.call(arguments), docFrag = document.createDocumentFragment();
        argArr.forEach(function(argItem) {
          var isNode = argItem instanceof Node;
          docFrag.appendChild(
            isNode ? argItem : document.createTextNode(String(argItem))
          );
        });
        this.appendChild(docFrag);
      }
    });
  });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);
(function() {
  if (typeof window.Event === "function")
    return false;
  function Event(event, params) {
    params = params || { bubbles: false, cancelable: false };
    var evt = document.createEvent("Event");
    evt.initEvent(event, params.bubbles, params.cancelable);
    return evt;
  }
  window.Event = Event;
})();
!function(t) {
  var e = {};
  function n(i) {
    if (e[i])
      return e[i].exports;
    var o = e[i] = { i, l: false, exports: {} };
    return t[i].call(o.exports, o, o.exports, n), o.l = true, o.exports;
  }
  n.m = t, n.c = e, n.d = function(t2, e2, i) {
    n.o(t2, e2) || Object.defineProperty(t2, e2, { enumerable: true, get: i });
  }, n.r = function(t2) {
    "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t2, Symbol.toStringTag, { value: "Module" }), Object.defineProperty(t2, "__esModule", { value: true });
  }, n.t = function(t2, e2) {
    if (1 & e2 && (t2 = n(t2)), 8 & e2)
      return t2;
    if (4 & e2 && "object" == typeof t2 && t2 && t2.__esModule)
      return t2;
    var i = /* @__PURE__ */ Object.create(null);
    if (n.r(i), Object.defineProperty(i, "default", { enumerable: true, value: t2 }), 2 & e2 && "string" != typeof t2)
      for (var o in t2)
        n.d(i, o, function(e3) {
          return t2[e3];
        }.bind(null, o));
    return i;
  }, n.n = function(t2) {
    var e2 = t2 && t2.__esModule ? function() {
      return t2.default;
    } : function() {
      return t2;
    };
    return n.d(e2, "a", e2), e2;
  }, n.o = function(t2, e2) {
    return Object.prototype.hasOwnProperty.call(t2, e2);
  }, n.p = "", n(n.s = 0);
}([function(t, e, n) {
  "use strict";
  n.r(e);
  var i = function() {
    function t2(t3) {
      var e2 = this;
      this.listener = function(t4) {
        (t4.matches ? e2.matchFns : e2.unmatchFns).forEach(function(t5) {
          t5();
        });
      }, this.toggler = window.matchMedia(t3), this.toggler.addListener(this.listener), this.matchFns = [], this.unmatchFns = [];
    }
    return t2.prototype.add = function(t3, e2) {
      this.matchFns.push(t3), this.unmatchFns.push(e2), (this.toggler.matches ? t3 : e2)();
    }, t2;
  }(), o = function(t2) {
    return Array.prototype.slice.call(t2);
  }, s = function(t2, e2) {
    return o((e2 || document).querySelectorAll(t2));
  }, r = ("ontouchstart" in window || navigator.msMaxTouchPoints, navigator.userAgent.indexOf("MSIE") > -1 || navigator.appVersion.indexOf("Trident/") > -1), a = "mm-spn", c = function() {
    function t2(t3, e2, n2, i2, o2) {
      this.node = t3, this.title = e2, this.slidingSubmenus = i2, this.selectedClass = n2, this.node.classList.add(a), r && (this.slidingSubmenus = false), this.node.classList.add(a + "--" + o2), this.node.classList.add(a + "--" + (this.slidingSubmenus ? "navbar" : "vertical")), this._setSelectedl(), this._initAnchors();
    }
    return Object.defineProperty(t2.prototype, "prefix", {
      get: function() {
        return a;
      },
      enumerable: false,
      configurable: true
    }), t2.prototype.openPanel = function(t3) {
      var e2 = t3.parentElement;
      if (this.slidingSubmenus) {
        var n2 = t3.dataset.mmSpnTitle;
        e2 === this.node ? this.node.classList.add(a + "--main") : (this.node.classList.remove(a + "--main"), n2 || o(e2.children).forEach(function(t4) {
          t4.matches("a, span") && (n2 = t4.textContent);
        })), n2 || (n2 = this.title), this.node.dataset.mmSpnTitle = n2, s(".mm-spn--open", this.node).forEach(function(t4) {
          t4.classList.remove(a + "--open"), t4.classList.remove(a + "--parent");
        }), t3.classList.add(a + "--open"), t3.classList.remove(a + "--parent");
        for (var i2 = t3.parentElement.closest("ul"); i2; )
          i2.classList.add(a + "--open"), i2.classList.add(a + "--parent"), i2 = i2.parentElement.closest("ul");
      } else {
        var r2 = t3.matches(".mm-spn--open");
        s(".mm-spn--open", this.node).forEach(function(t4) {
          t4.classList.remove(a + "--open");
        }), t3.classList[r2 ? "remove" : "add"](a + "--open");
        for (var c2 = t3.parentElement.closest("ul"); c2; )
          c2.classList.add(a + "--open"), c2 = c2.parentElement.closest("ul");
      }
    }, t2.prototype._setSelectedl = function() {
      var t3 = s("." + this.selectedClass, this.node), e2 = t3[t3.length - 1], n2 = null;
      e2 && (n2 = e2.closest("ul")), n2 || (n2 = this.node.querySelector("ul")), this.openPanel(n2);
    }, t2.prototype._initAnchors = function() {
      var t3 = this;
      this.node.addEventListener("click", function(e2) {
        var n2 = e2.target, i2 = false;
        (i2 = (i2 = (i2 = i2 || function(t4) {
          return !!t4.matches("a");
        }(n2)) || function(e3) {
          var n3;
          return !!(n3 = e3.closest("span") ? e3.parentElement : !!e3.closest("li") && e3) && (o(n3.children).forEach(function(e4) {
            e4.matches("ul") && t3.openPanel(e4);
          }), true);
        }(n2)) || function(e3) {
          var n3 = s(".mm-spn--open", e3), i3 = n3[n3.length - 1];
          if (i3) {
            var o2 = i3.parentElement.closest("ul");
            if (o2)
              return t3.openPanel(o2), true;
          }
          return false;
        }(n2)) && e2.stopImmediatePropagation();
      });
    }, t2;
  }(), d = function() {
    function t2(t3, e2) {
      var n2 = this;
      void 0 === t3 && (t3 = null), this.wrapper = document.createElement("div"), this.wrapper.classList.add("mm-ocd"), this.wrapper.classList.add("mm-ocd--" + e2), this.content = document.createElement("div"), this.content.classList.add("mm-ocd__content"), this.wrapper.append(this.content), this.backdrop = document.createElement("div"), this.backdrop.classList.add("mm-ocd__backdrop"), this.wrapper.append(this.backdrop), document.body.append(this.wrapper), t3 && this.content.append(t3);
      var i2 = function(t4) {
        n2.close(), t4.stopImmediatePropagation();
      };
      this.backdrop.addEventListener("touchstart", i2, { passive: true }), this.backdrop.addEventListener("mousedown", i2, { passive: true });
    }
    return Object.defineProperty(t2.prototype, "prefix", {
      get: function() {
        return "mm-ocd";
      },
      enumerable: false,
      configurable: true
    }), t2.prototype.open = function() {
      this.wrapper.classList.add("mm-ocd--open"), document.body.classList.add("mm-ocd-opened");
    }, t2.prototype.close = function() {
      this.wrapper.classList.remove("mm-ocd--open"), document.body.classList.remove("mm-ocd-opened");
    }, t2;
  }(), u = function() {
    function t2(t3, e2) {
      void 0 === e2 && (e2 = "all"), this.menu = t3, this.toggler = new i(e2);
    }
    return t2.prototype.navigation = function(t3) {
      var e2 = this;
      if (!this.navigator) {
        var n2 = (t3 = t3 || {}).title, i2 = void 0 === n2 ? "Menu" : n2, o2 = t3.selectedClass, s2 = void 0 === o2 ? "Selected" : o2, r2 = t3.slidingSubmenus, a2 = void 0 === r2 || r2, d2 = t3.theme, u2 = void 0 === d2 ? "light" : d2;
        this.navigator = new c(this.menu, i2, s2, a2, u2), this.toggler.add(function() {
          return e2.menu.classList.add(e2.navigator.prefix);
        }, function() {
          return e2.menu.classList.remove(e2.navigator.prefix);
        });
      }
      return this.navigator;
    }, t2.prototype.offcanvas = function(t3) {
      var e2 = this;
      if (!this.drawer) {
        var n2 = (t3 = t3 || {}).position, i2 = void 0 === n2 ? "left" : n2;
        this.drawer = new d(null, i2);
        var o2 = document.createComment("original menu location");
        this.menu.after(o2), this.toggler.add(function() {
          e2.drawer.content.append(e2.menu);
        }, function() {
          e2.drawer.close(), o2.after(e2.menu);
        });
      }
      return this.drawer;
    }, t2;
  }();
  e.default = u;
  window.MmenuLight = u;
}]);
